import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { SvgIcon, SvgIconProps } from '@mui/material'

const RecipeTimeIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 20 20' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <path
          d='M10 18.75c-4.832 0-8.75-3.918-8.75-8.75S5.168 1.25 10 1.25s8.75 3.918 8.75 8.75-3.918 8.75-8.75 8.75zm0-2.5c3.452 0 6.25-2.798 6.25-6.25S13.452 3.75 10 3.75 3.75 6.548 3.75 10s2.798 6.25 6.25 6.25zm1.25-6.206l1.634 1.607c.488.48.488 1.259 0 1.739-.488.48-1.28.48-1.768 0l-2-1.967c-.234-.23-.366-.544-.366-.87V7.48c0-.68.56-1.23 1.25-1.23s1.25.55 1.25 1.23v2.564z'
          transform='translate(-35.000000, -503.000000) translate(20.000000, 180.000000) translate(15.000000, 225.000000) translate(0.000000, 95.000000) translate(0.000000, 3.000000)'
        />
      </g>
    </g>
  </SvgIcon>
)

export default RecipeTimeIcon
