import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { SvgIcon, SvgIconProps } from '@mui/material'

const WeighIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M21.2360049,2.6798123 C21.4040004,3.19367443 21.1236202,3.74642931 20.6097581,3.91442488 C20.6086491,3.91478743 20.6075395,3.91514799 20.6064293,3.91550657 L19.194,4.371 L21.9810655,10.823521 C22.1323219,11.1737336 21.4052771,12.5872043 18.7886634,12.6752042 L18.52951,12.6795024 C15.7015629,12.6795024 14.861771,11.1850308 15.0179066,10.823521 L15.0179066,10.823521 L17.578,4.893 L12.999,6.372 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L10.999,7.018 L6.27,8.545 L8.98106546,14.823521 C9.13232185,15.1737336 8.40527714,16.5872043 5.78866339,16.6752042 L5.52951,16.6795024 C2.70156291,16.6795024 1.86177097,15.1850308 2.01790659,14.823521 L2.01790659,14.823521 L4.479,9.124 L3.4475061,9.45739496 C2.93066393,9.62432161 2.37615914,9.3418062 2.20738552,8.82556419 C2.03938995,8.31170207 2.31977019,7.75894719 2.83363231,7.59095162 C2.83474127,7.59058907 2.83585088,7.5902285 2.83696112,7.58986992 L19.9958843,2.04798153 C20.5127265,1.88105488 21.0672312,2.1635703 21.2360049,2.6798123 Z M5.49948603,9.6963413 L3.4341794,14.4797351 L7.56596746,14.4797351 L5.49948603,9.6963413 Z M18.499486,5.6963413 L16.4341794,10.4797351 L20.5659675,10.4797351 L18.499486,5.6963413 Z'
      fillRule='nonzero'
    />
  </SvgIcon>
)

export default WeighIcon
