import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { SvgIcon, SvgIconProps } from '@mui/material'

const ArrowDownIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M10.592 5v11.17l-4.88-4.88c-.39-.39-1.03-.39-1.42 0a.996.996 0 0 0 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0l6.59-6.59a.996.996 0 1 0-1.41-1.41l-4.88 4.88V5c0-.55-.45-1-1-1s-1 .45-1 1z'
      fillRule='nonzero'
    />
  </SvgIcon>
)

export default ArrowDownIcon
