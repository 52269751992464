import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { SvgIcon, SvgIconProps } from '@mui/material'

const CloseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M17.8925,5.3025 C17.5025,4.9125 16.8725,4.9125 16.4825,5.3025 L11.5925,10.1825 L6.7025,5.2925 C6.3125,4.9025 5.6825,4.9025 5.2925,5.2925 C4.9025,5.6825 4.9025,6.3125 5.2925,6.7025 L10.1825,11.5925 L5.2925,16.4825 C4.9025,16.8725 4.9025,17.5025 5.2925,17.8925 C5.6825,18.2825 6.3125,18.2825 6.7025,17.8925 L11.5925,13.0025 L16.4825,17.8925 C16.8725,18.2825 17.5025,18.2825 17.8925,17.8925 C18.2825,17.5025 18.2825,16.8725 17.8925,16.4825 L13.0025,11.5925 L17.8925,6.7025 C18.2725,6.3225 18.2725,5.6825 17.8925,5.3025 Z'
      fillRule='nonzero'
    />
  </SvgIcon>
)

export default CloseIcon
