import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { SvgIcon, SvgIconProps } from '@mui/material'

const CheckIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <g>
          <path
            d='M9 15.586l9.293-9.293c.39-.39 1.024-.39 1.414 0 .39.39.39 1.024 0 1.414l-10 10c-.39.39-1.024.39-1.414 0l-4-4c-.39-.39-.39-1.024 0-1.414.39-.39 1.024-.39 1.414 0L9 15.586z'
            transform='translate(-566 -252) translate(566 252)'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default CheckIcon
