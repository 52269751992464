import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { SvgIcon, SvgIconProps } from '@mui/material'

const RecipeVerifiedIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 20 20' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <path
          d='M7.422 12.73l7.544-8.553c.58-.57 1.52-.57 2.1 0 .579.57.579 1.493 0 2.063L8.47 15.823c-.58.57-1.52.57-2.099 0L2.935 12.49c-.58-.57-.58-1.493 0-2.063.58-.57 1.52-.57 2.099 0l2.388 2.302z'
          transform='translate(-543.000000, -471.000000) translate(20.000000, 180.000000) translate(334.000000, 0.000000) translate(15.000000, 225.000000) translate(0.000000, 62.000000) translate(174.000000, 4.000000)'
        />
      </g>
    </g>
  </SvgIcon>
)

export default RecipeVerifiedIcon
