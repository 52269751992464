import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { SvgIcon, SvgIconProps } from '@mui/material'

const RemoveIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M17,12 C17,12.55 16.55,13 16,13 L8,13 C7.45,13 7,12.55 7,12 C7,11.45 7.45,11 8,11 L16,11 C16.55,11 17,11.45 17,12 Z'
      fillRule='nonzero'
    />
  </SvgIcon>
)

export default RemoveIcon
