import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { SvgIcon, SvgIconProps } from '@mui/material'

const StarIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g fillRule='nonzero'>
      <path d='M6.786 21.79c-1.07.51-2.325-.32-2.068-1.54l1.535-5.626-4.788-3.715c-.84-.803-.422-2.3.855-2.299l6.158-.368 2.276-5.302c.305-1.165 1.976-1.165 2.543-.073l2.176 5.507 6.102.366c1.346 0 1.9 1.59.844 2.346l-4.734 3.67 1.537 5.632c.292 1.116-1.035 1.897-2.138 1.365l-5.116-3.179-5.182 3.215z' />
    </g>
  </SvgIcon>
)

export default StarIcon
