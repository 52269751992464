import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { SvgIcon, SvgIconProps } from '@mui/material'

const PlayIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M7 6.972v10.056c0 .767.858 1.233 1.518.815l8.024-5.028a.963.963 0 0 0 0-1.64L8.518 6.157C7.858 5.739 7 6.205 7 6.972z'
      fillRule='nonzero'
    />
  </SvgIcon>
)

export default PlayIcon
