import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { SvgIcon, SvgIconProps } from '@mui/material'

const ApplianceSettingTemperatureThermomixIcon: React.FC<SvgIconProps> = (
  props
) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M9.667 4.5a2.5 2.5 0 0 1 5 0v10l.084.065a4.167 4.167 0 1 1-5.085-.064V4.5zm2.5-.833a.833.833 0 0 0-.828.736l-.006.097v4.167H13V4.5a.833.833 0 0 0-.736-.828l-.097-.005z'
      fillRule='nonzero'
    />
  </SvgIcon>
)

export default ApplianceSettingTemperatureThermomixIcon
