import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { SvgIcon, SvgIconProps } from '@mui/material'

const ApplianceSettingTimeThermomixIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M11.9893311,22 C17.5207222,22 22,17.5178073 22,12 C22,6.47891022 17.5207222,2 11.9893311,2 C6.46942963,2 2,6.47891022 2,12 C2,17.5178073 6.46942963,22 11.9893311,22 Z M3.99917932,12.0009845 C3.99917932,7.58170248 7.58063192,3.99934351 11.9991793,3.99934351 L11.9991793,3.99934351 C16.4193681,3.99934351 20.0008207,7.58170248 20.0008207,12.0009845 L20.0008207,12.0009845 C20.0008207,16.4186256 16.4193681,19.9993435 11.9991793,19.9993435 L11.9991793,19.9993435 C7.58063192,19.9993435 3.99917932,16.4186256 3.99917932,12.0009845 L3.99917932,12.0009845 Z M10.6680345,6.66601018 L10.6680345,12.786148 L16.5014362,15.9980305 L17.3336069,14.7441326 L12.3340172,12.0196947 L12.3340172,6.66601018 L10.6680345,6.66601018 Z'
      fillRule='nonzero'
    />
  </SvgIcon>
)

export default ApplianceSettingTimeThermomixIcon
