import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { SvgIcon, SvgIconProps } from '@mui/material'

const ApplianceSettingSpeedThermomixIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M11.251 6.336c-.094-1.188-.542-2.307-1.343-3.357a.714.714 0 0 1 1.221-.711c1.397 1.137 2.275 2.224 2.636 3.26.36 1.038.36 2.48 0 4.326-.497-.434-1.051-.712-1.766-.712-.578 0-1.117.176-1.566.476.64-1 .912-2.094.818-3.282zm-5.588 3.9c1.011-.352 2.408-.352 4.19 0-.435.497-.71 1.053-.71 1.766 0 .577.177 1.116.474 1.566a5.18 5.18 0 0 0-3.28-.819c-1.188.094-2.306.54-3.356 1.341a.713.713 0 0 1-.712-1.22c1.252-1.405 2.384-2.283 3.394-2.634zm7.087 7.427c.093 1.187.54 2.306 1.342 3.357a.72.72 0 0 1-.303.886.742.742 0 0 1-.36.094.71.71 0 0 1-.557-.268c-1.405-1.23-2.284-2.35-2.637-3.362-.353-1.012-.353-2.41 0-4.198.495.42 1.067.685 1.764.685.582 0 1.119-.177 1.57-.475-.64 1-.913 2.093-.82 3.28zm9.152-7.452a.713.713 0 0 1-.17.918c-1.23 1.405-2.35 2.284-3.362 2.637-1.011.352-2.41.352-4.196 0 .417-.496.684-1.07.684-1.764 0-.581-.177-1.12-.474-1.57a5.15 5.15 0 0 0 3.28.819c1.194-.094 2.313-.542 3.356-1.342a.713.713 0 0 1 .882.303zm-10.48 1.212a.822.822 0 0 1 .58-.223.81.81 0 0 1 .575.222.8.8 0 0 1 .24.58c0 .22-.083.42-.24.576a.795.795 0 0 1-.578.239.817.817 0 0 1-.577-1.394zm1.873 1.87c.345-.343.535-.803.535-1.292 0-.491-.19-.95-.535-1.296a1.824 1.824 0 0 0-1.293-.518c-.475 0-.95.173-1.297.518A1.833 1.833 0 0 0 12 13.831c.489 0 .949-.188 1.296-.537z'
      fillRule='nonzero'
    />
  </SvgIcon>
)

export default ApplianceSettingSpeedThermomixIcon
