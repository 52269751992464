import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { SvgIcon, SvgIconProps } from '@mui/material'

const PauseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M8 18c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2s-2 .9-2 2v8c0 1.1.9 2 2 2zm6-10v8c0 1.1.9 2 2 2s2-.9 2-2V8c0-1.1-.9-2-2-2s-2 .9-2 2z'
      fillRule='nonzero'
    />
  </SvgIcon>
)

export default PauseIcon
